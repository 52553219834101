var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',{staticClass:"background__logo mb-10",staticStyle:{"height":"100%"}},[_c('v-container',[_c('v-row',{staticClass:"my-2 mx-5",attrs:{"id":"description-row"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"ma-0 v-flex align-center"},[_c('v-col',{staticClass:"pa-0 mr-3",attrs:{"cols":"auto"}},[_c('v-icon',{staticClass:"title-icon"},[_vm._v("toys")])],1),_c('v-col',{staticClass:"pa-0"},[_c('h1',{staticClass:"my-0"},[_vm._v("Apariencia")])])],1),_c('v-row',{staticClass:"pt-3"},[_c('v-col',[_c('p',{staticClass:"ma-0",attrs:{"id":"text-description"}},[_vm._v(" Aquí puedes modificar desde los colocares hasta el logo, icono e imagen de fondo del ambiente en el que trabajas. Además puedes agregar código CSS para todo el ambiente, así puedes personalizar tu ambiente al detalle. ")])])],1)],1)],1),(_vm.$apollo.loading)?_c('Loading'):(_vm.environment)?_c('v-card',{staticClass:"d-flex mx-8 overflow-hidden",attrs:{"id":"appearance-card","elevation":"5","height":"100%"}},[_c('v-row',{staticStyle:{"position":"relative"},attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"height":"100%"}},[_c('div',{staticClass:"py-2 px-3",attrs:{"id":"collection-list__header--title"}},[_c('span',{attrs:{"id":"header__title"}},[_vm._v("Apariencia")])]),_c('div',{staticStyle:{"height":"calc(100% - 44px)"},attrs:{"id":"collection-detail__body"}},[_c('v-card-text',{staticStyle:{"height":"calc(100% - 52px)"}},[_c('Fields',{attrs:{"id":"appearance-fields","schema":{
                  accentColor: {
                    type: 'string',
                    fieldType: 'color',
                    label: 'Color Primario',
                    optional: true,
                    sizeSmall: 12,
                    sizeMedium: 4,
                    sizeLarge: 3,
                    newDesign: true
                  },
                  sideBarColor: {
                    type: 'string',
                    fieldType: 'color',
                    label: 'Color Fondo Sidebar',
                    optional: true,
                    sizeSmall: 12,
                    sizeMedium: 4,
                    sizeLarge: 3,
                    newDesign: true
                  },
                  sideBarTextColor: {
                    type: 'string',
                    fieldType: 'color',
                    label: 'Color Texto Sidebar',
                    optional: true,
                    sizeSmall: 12,
                    sizeMedium: 4,
                    sizeLarge: 3,
                    newDesign: true
                  },
                  backgroundColor: {
                    type: 'string',
                    fieldType: 'color',
                    label: 'Color Fondo Vistas',
                    optional: true,
                    sizeSmall: 12,
                    sizeMedium: 4,
                    sizeLarge: 3,
                    newDesign: true
                  },
                  allowDarkMode: {
                    type: 'boolean',
                    fieldType: 'checkbox',
                    label: 'Permitir habilitar el modo oscuro',
                    sizeSmall: 12,
                    optional: true,
                    newDesign: true
                  },
                  authBackgroundImage: {
                    type: 'file',
                    fieldType: 'file',
                    fieldOptions: {
                      fileType: 'image'
                    },
                    label: 'Imagen de Fondo Login',
                    optional: true,
                    sizeSmall: 12,
                    sizeMedium: 6,
                    sizeLarge: 6,
                    newDesign: true
                  },
                  logo: {
                    type: 'file',
                    fieldType: 'file',
                    fieldOptions: {
                      fileType: 'image'
                    },
                    label: 'Logo',
                    optional: true,
                    sizeSmall: 12,
                    sizeMedium: 6,
                    sizeLarge: 6,
                    newDesign: true
                  },
                  loginLogo: {
                    type: 'file',
                    fieldType: 'file',
                    fieldOptions: {
                      fileType: 'image'
                    },
                    label: 'Logo Login',
                    optional: true,
                    sizeSmall: 12,
                    sizeMedium: 6,
                    sizeLarge: 6,
                    newDesign: true
                  },
                  icon: {
                    type: 'file',
                    fieldType: 'file',
                    fieldOptions: {
                      fileType: 'image'
                    },
                    label: 'Ícono',
                    optional: true,
                    sizeSmall: 12,
                    sizeMedium: 6,
                    sizeLarge: 6,
                    newDesign: true
                  }
                },"disabled":_vm.$apollo.loading || _vm.saving,"validationErrors":{}},model:{value:(_vm.environment),callback:function ($$v) {_vm.environment=$$v},expression:"environment"}}),_c('v-container',{staticClass:"py-0"},[_c('CheckBox',{staticClass:"pa-1",attrs:{"name":"useCustomCss","schema":{
                    label: 'Usar CSS personalizado',
                    optional: true,
                    newDesign: true
                  }},model:{value:(_vm.environment.useCustomCss),callback:function ($$v) {_vm.$set(_vm.environment, "useCustomCss", $$v)},expression:"environment.useCustomCss"}})],1),_c('v-slide-y-transition',[(_vm.environment.useCustomCss)?_c('Fields',{attrs:{"schema":{
                    customCss: {
                      type: 'string',
                      fieldType: 'code',
                      fieldOptions: {
                        mode: 'text/css'
                      },
                      label: 'CSS Personalizado',
                      optional: true,
                      sizeSmall: 12
                    }
                  },"disabled":_vm.$apollo.loading || _vm.saving,"validationErrors":{}},model:{value:(_vm.environment),callback:function ($$v) {_vm.environment=$$v},expression:"environment"}}):_vm._e()],1),(!_vm.desktopApp)?_c('v-checkbox',{staticClass:"ml-4",attrs:{"label":"Personalizar correo de bienvenida"},model:{value:(_vm.environment.customizeWelcomeEmail),callback:function ($$v) {_vm.$set(_vm.environment, "customizeWelcomeEmail", $$v)},expression:"environment.customizeWelcomeEmail"}}):_vm._e(),(_vm.environment.customizeWelcomeEmail)?_c('Fields',{attrs:{"schema":{
                  welcomeEmailSubject: {
                    type: 'string',
                    label: 'Asunto del correo de bienvenida',
                    fieldOptions: {
                      placeholder: 'Bienvenido/a a Simplex!'
                    },
                    optional: true,
                    sizeSmall: 12,
                    newDesign: true
                  },
                  welcomeEmailBody: {
                    type: 'string',
                    fieldType: 'html',
                    label: 'Template para correo de bienvenida',
                    description:
                      'Variables disponibles: <%= email %>, <%= password %>, <%= url %>',
                    optional: true
                  }
                },"disabled":_vm.$apollo.loading || _vm.saving,"validationErrors":{}},model:{value:(_vm.environment),callback:function ($$v) {_vm.environment=$$v},expression:"environment"}}):_vm._e(),(!_vm.desktopApp)?_c('v-checkbox',{staticClass:"ml-4",attrs:{"label":"Personalizar correo de restablecimiento de contraseña"},model:{value:(_vm.environment.customizeResetPasswordEmail),callback:function ($$v) {_vm.$set(_vm.environment, "customizeResetPasswordEmail", $$v)},expression:"environment.customizeResetPasswordEmail"}}):_vm._e(),(_vm.environment.customizeResetPasswordEmail)?_c('Fields',{attrs:{"schema":{
                  resetPasswordEmailSubject: {
                    type: 'string',
                    label:
                      'Asunto del correo de restablecimiento de contraseña',
                    fieldOptions: {
                      placeholder: 'Recuperación de contraseña'
                    },
                    optional: true,
                    sizeSmall: 12,
                    newDesign: true
                  },
                  resetPasswordEmailBody: {
                    type: 'string',
                    fieldType: 'html',
                    label:
                      'Template para correo de restablecimiento de contraseña',
                    description: 'Variables disponibles: <%= url %>',
                    optional: true
                  }
                },"disabled":_vm.$apollo.loading || _vm.saving,"validationErrors":{}},model:{value:(_vm.environment),callback:function ($$v) {_vm.environment=$$v},expression:"environment"}}):_vm._e()],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","icon":"","title":"Revertir Cambios","disabled":!_vm.dirty || _vm.saving},on:{"click":_vm.update}},[_c('v-icon',[_vm._v("undo")])],1),_c('v-flex'),_c('v-btn',{attrs:{"color":"#59a4f4","dark":!_vm.dirty ? false : true,"disabled":!_vm.dirty,"loading":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.save}},[_vm._v("Guardar configuración")])],1)],1)])],1)],1):_vm._e()],1),_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"bottom":"","timeout":5000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" "),_c('v-btn',{attrs:{"color":_vm.snackbarColor ? '' : 'primary',"text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v(" OK ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }